.formbox .input-group {
    border: 1px solid #dee2e6;
    border-radius: 5px;
}


.formbox .input-group span {
    padding: 6px;
}


.textblue {
    color: #ed3237 !important;
}

.bgblue,
.bg-blue {
    background-color: #f89e32 !important;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable {
    background: rgb(0, 0, 0);
    background: radial-gradient(circle, rgba(0, 0, 0, 1) 0%);
    color: white;
}

.actionbtn {
    background-color: #ed3237 !important;
    color: white !important;
}

.MuiStack-root.css-v0pjtz-MuiStack-root {
    padding-top: 0px;
}


.MuiFormControl-root.MuiTextField-root.css-z3c6am-MuiFormControl-root-MuiTextField-root {
    width: 100%;
    background: white;
}


.stdropdown-input.stsearch-box input {
    border: 0;

}

.stdropdown-input.stsearch-box input:focus-visible {
    border: 0;
    outline: none;
}

.stsearch-box {
    padding: 0 !important;
    background-color: white !important;
    border-radius: 4px;
    box-shadow: 0 0 4px white;
}