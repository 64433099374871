@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500&display=swap");

body {
    font-family: "Jost", sans-serif;
}

.loginbox {
    background-color: white;
    box-shadow: 0 0 10px !important;
    min-height: 100%;
}



.box1 {
    opacity: 0;
}

.container.animate {
    opacity: 1;
    transition: opacity 1.5s ease;
}

.animate-left {
    transform: translateY(-100%);
    transition: transform 1.5s ease;
}

.animate-right {
    transform: translateY(-100%);
    transition: transform 2s ease;
}

.animate-left.slide-in-left,
.animate-right.slide-in-right {
    transform: translateY(0);
}

.loginpage {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* background: linear-gradient(315deg, rgba(204, 122, 81, 1) 0%, rgba(47, 77, 161, 1) 100%); */
    background-image: url('../assets/images/loginbg.jpg');
    height: 100%;
    min-height: 100vh;
}